import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import SEO from '../components/Layout/SEO'
import Ailment from '../components/Ailment'
import { AILMENT } from '../components/Layout/routes'

 function AilmentTemplate(props) {
     let {mongodbHerbsAilments} = props.data;
     let {slug, name, description, ogImage1200x628} = mongodbHerbsAilments
     const ailmentDescription = description ? description.substring(0, 150) : "This ailment has no description";
     const ailmentPath = `${AILMENT}/${slug}`
    const seoProps = {
        title: `${name} Symptoms, and Natural Remedies`,
        description: ailmentDescription,
        type: "website",
        pathname: ailmentPath,
        image: {
            width: 1200,
            height: 628,
            src: ogImage1200x628
        }
    }

    return (
        <Layout seoProps={seoProps}>
            <Ailment data={props.data} />
        </Layout>
    )
}

AilmentTemplate.defaultProps = {
    query: {
        slug: ''
    }
}

AilmentTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
}

export const ailmentQuery = graphql`
  query($id: String!) {
    mongodbHerbsAilments(id: {eq: $id}) {
    slug
    name
    description
    ogImage1200x628
    mongodb_id
    internal {
          content
        }
    }
  }
`

export default AilmentTemplate

